.clr-accordion-header {
	padding: 0.5rem 0;
}

.clr-accordion-panel {
	margin-bottom: 0.5rem;
	border-bottom: 1px solid var(--clr-color-neutral-400);
}

.clr-accordion {
	border-bottom: none;
}

.clr-accordion:not(.clr-stepper-forms) .clr-accordion-inner-content {
	padding: 0.3rem 1.75rem 1rem;
}
