$base-color: rgb(221 221 221 / 64.4%);
$ghost-color: #ecebebf1;
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;

@mixin background-gradient {
	background-image: linear-gradient(90deg, $base-color 0, $ghost-color 40px, $base-color 80px);
	background-size: 80vw;
}

@keyframes ghost-lines {
	0% {
		background-position: -100px;
	}

	40% {
		background-position: 40vw;
	}

	100% {
		background-position: 60vw;
	}
}

@mixin animate-ghost-line {
	@include background-gradient;

	animation: ghost-lines $animation-duration infinite linear;
}
